<template>
  <div class="video-page">
    <div class="top-nav">
      <div class="content-wrap">
        当前位置：<span @click="toHome('')">首页</span><span @click="toBack()">{{ courseInfo.category && courseInfo.category.name ? ' > ' + courseInfo.category.name : '' }}</span> > <span @click="toBack()">{{ courseInfo.name }}</span>
      </div>
    </div>
    <div class="video-content">
      <div class="video-play-wrap" id="videoNode" :style="{'width' : isOpen ? 'calc( 100% - 360px )' : '100%', 'margin-left' : isOpen ? '360px' : '0'}">
        <div id="J_prismPlayer" style="width: 100%; height: 100%"></div>
        <!-- <div v-show="isMute" class="mute-close-icon" @click="closeMute()" >
          <img src="../assets/img/voice-close-icon.png" class="mute-img">
          <span>声音已关闭，点击开启</span>
        </div> -->
        <div v-if="bLoading" class="loading-wrap" v-loading="bLoading" :loading.sync="bLoading" element-loading-text="加载中..." element-loading-background="#282828"></div>
      </div>
      <div v-if="!isOpen" class="open-btn" @click="isOpen = true">
        <img src="../assets/img/video_open_icon.png" />
      </div>
      <div v-show="isOpen" class="left-catalog">
        <div class="catalog-left">
          <div class="catalog-left-content">
            <img src="../assets/img/video_catalog_icon.png" />
            <span>目录</span>
          </div>
        </div>
        <div class="list-wrap">
          <el-tree
            ref="tree"
            :data="catalogData"
            highlight-current
            :props="defaultProps"
            node-key="id"
            :current-node-key="curCatalogID"
            :default-expanded-keys="[curCatalogID]"
            :default-checked-keys="[curCatalogID]"
            @node-click="handleNodeClick"
          >
            <div class="catalog-wrap" slot-scope="{ node, data }">
              <div class="catalog-wrap-item" v-if="data.children && data.children.length > 0">
                <span :id="data.id" class="catalog-name">{{ node.label }}</span>
              </div>
              <div class="catalog-wrap-item catalog-wrap-last" v-else>
                <template v-if="data.video">
                  <div class="top">
                    <div class="top-image" />
                    <span :id="data.id" class="catalog-name">{{ node.label }}</span>
                    <template v-if="data.video && data.video.is_over === 1">
                      <img class="video-view-process" src="../assets/img/video_over.png" />
                    </template>
                    <template v-else>
                      <img class="video-view-process" v-if="data.video.video_times > 0 && data.video.video_times < data.video.video_duration" src="../assets/img/video_playing.png" />
                      <img class="video-view-process" v-else-if="data.video.video_times >= data.video.video_duration" src="../assets/img/video_over.png" />
                      <img class="video-view-process" v-else src="../assets/img/video_no_view.png" />
                    </template>
                  </div>
                  <div class="bottom">
                    <span>录播</span>
                    <div>{{ data.video.video_times > data.video.video_duration ? formatTime(data.video.video_duration) : data.id === curCatalogID ? formatTime(player.getCurrentTime()) : formatTime(data.video.video_times)}} / {{ formatTime(data.video.video_duration) }}</div>
                  </div>
                </template>
              </div>
            </div>
          </el-tree>
        </div>
        <div class="close-btn" @click="isOpen = false">
          <img src="../assets/img/video_close_icon.png" />
        </div>
      </div>
    </div>
    <application-dialog :application-dialog="applicationDialog" @hideDialog="hideApplicationDialog" />
    <download-dialog :download-dialog="downloadDialog" @hideDialog="hideDownloadDialog" />
    <loading :is-load="loading" />
  </div>
</template>

<script charset="utf-8" type="text/javascript" src="https://g.alicdn.com/apsara-media-box/imp-web-player/2.25.0/aliplayer-min.js"></script>
<script>
import * as courseService from '@/service/course-service';
import playerNextComponent from './components/playerNextComponent';
import QualityComponent from './components/QualityComponent';
import RateComponent from './components/RateComponent';
import { Message } from 'element-ui';
import applicationDialog from '@/views/modules/application-dialog';
import downloadDialog from '@/views/modules/download-dialog';
export default {
  name: 'coursePlayer',
  components: {
    applicationDialog,
    downloadDialog,
    QualityComponent
  },
  data() {
    return {
      bLoading: true,
      loading: false,
      isOpen: true,
      applicationDialog: false,
      downloadDialog: false,
      catalogData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], // 视频播放倍速
      courseInfo: {}, // 课程详情
      curCatalogID: '', // 当前选中视频章节id
      curCatalogInfo: {}, // 当前选中视频
      videoUrl: '', // 当前视频url
      isTry: false, // 是否试看
      nextCatalog: {}, // 下一课时
      showFluent: false,
      fluent: '标清',
      player: null,
      saveLogTimeInterval: null,
      isMute: true, // 是否静音
      markers:[],
      video_times:0
    };
  },
  created() {
    this.getCourseInfo();
    this.isTry = !!+this.$route.query.isTry;
    window.addEventListener('beforeunload', this.handleBeforeunload);
  },
  mounted() {},
  methods: {
    handleBeforeunload(event){
      // event.preventDefault();
      // event.returnValue = '';
    },
    stopvideo(){
      var status = this.player.getStatus()
      if(status=='playing'){
        this.player.pause(true)
      }
      if(status=='pause'){
        this.player.play()
      }
    },
    setVideo() {
      var that = this
      const props = {
        id: 'J_prismPlayer',
        width: '100%',
        height: '100%',
        autoplay: true,
        source: this.videoUrl,
        controlBarVisibility: 'hover',
        keyShortCuts: true, // 是否启用快捷键
        keyFastForwardStep: 10, // 快进快退的时间长度
        progressMarkers:this.markers,
        defaultDefinition:'HD',
        components: [
          {
            name: 'playerNextComponent',
            type: playerNextComponent,
            args: [this.playNextVideo]
          }, {
            name: 'RateComponent',
            type: RateComponent
          },{
            name: 'QualityComponent',
            type: QualityComponent,
            args: [function(definition,desc) {}]
        }],
        skinLayout: [
          {
            name: 'H5Loading', align: 'cc'
          },
          { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
          { name: 'infoDisplay' },
          { name: 'tooltip', align: 'blabs', x: 0, y: 76 },
          { name: 'thumbnail' },
          {
            name: 'controlBar',
            align: 'blabs',
            x: 0,
            y: 0,
            children: [
              { name: 'progress', align: 'blabs', x: 0, y: 0 },
              { name: 'playButton', align: 'tl', x: 10, y: 16 },
              { name: 'timeDisplay', align: 'tl', x: 24, y: 9 },
              { name: 'fullScreenButton', align: 'tr', x: 40, y: 14 },
              {name:"streamButton", align:"tr", x:10, y:23},
              { name: 'volume', align: 'tr', x: 10, y: 14 }
            ]
          }
        ]
      };
      this.player = new Aliplayer(props);
      // this.player.setProgressMarkers(masks);
      this.player.mute();
      this.player.on('play', this.videoPlay);
      this.player.on('ended', this.videoPlayEnded);
      this.player.on('timeupdate', () => {
        this.reWriteVideoTime(this.catalogData, this.player.getCurrentTime());
        if (!this.saveLogTimeInterval) {
          this.saveLogTimeInterval = setInterval(() => {
            this.setUserLiveLog();
          }, 5000);
        }
      });
      this.player.on('pause', () => {
        clearInterval(this.saveLogTimeInterval);
        this.saveLogTimeInterval = null;
        that.video_times = this.player.getCurrentTime()
        console.log(that.video_times)
      });
      var playerTag = this.player.tag
      playerTag.addEventListener('click',function(){
        that.stopvideo()
      })
      setTimeout(() => {
        this.player.setVolume(1);
      }, 500);
      setTimeout(() => {
        this.player.play()
      }, 1000);
    },
    /*
    * 视频播放
    * */
    videoPlay() {
      // 设置上次播放时间
      this.bLoading = false;
      console.log(this.video_times)
      if (this.curCatalogInfo.video.video_duration - this.curCatalogInfo.video.video_times > 1) {
        this.player.seek(this.video_times);
      }
    },
    /**
     * 视频播放结束
     * */
    async videoPlayEnded() {
      this.getAfterNode(this.catalogData, this.$refs.tree.getNode(this.curCatalogID).data);
      await this.setUserLiveLog(1);
      this.curCatalogID = this.nextCatalog.id;
      if (this.nextCatalog.video) {
        this.videoUrl = JSON.stringify({"HD":this.nextCatalog.video.video_url,"SD":this.nextCatalog.video.video_url});
        this.$refs.tree.setCurrentKey(this.curCatalogID);
        this.curCatalogInfo = this.nextCatalog;
        this.video_times = this.curCatalogInfo.video.video_times
        this.player.dispose()
        this.setVideo();
      }
    },
    /**
     * 下一课时
     * */
    async playNextVideo() {
      if (!this.isTry) {
        await this.setUserLiveLog();
        this.curCatalogID = this.nextCatalog.id;
        if (this.nextCatalog.video) {
          this.videoUrl = JSON.stringify({"HD":this.nextCatalog.video.video_url,"SD":this.nextCatalog.video.video_url});
          this.$refs.tree.setCurrentKey(this.curCatalogID);
          this.curCatalogInfo = this.nextCatalog;
          this.video_times = this.curCatalogInfo.video.video_times
          this.player.dispose()
          this.setVideo();
        }
        this.getAfterNode(this.catalogData, this.$refs.tree.getNode(this.curCatalogID).data);
      }
      else {
        Message({
          message: '此视频为试看',
          type: 'warning',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
      }
    },
    /**
     * 查询课程详情
     * */
    getCourseInfo() {
      const data = {
        id: this.$route.query.id,
        type:'pc'
      };
      var that = this
      this.loading = true;
      courseService.getCourseInfo(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.courseInfo = res.data;
          // course_user1_count > 0 已经报名或者已经购买 == 0 未报名或未购买
          if (this.courseInfo.course_user1_count > 0) {
            this.isTry = false;
          }
          this.catalogData = res.data.outline;
          this.$nextTick(() => {
            this.bLoading = false;
            this.curCatalogID = Number(this.$route.query.catalogID);
            this.$refs.tree.setCurrentKey(this.curCatalogID);
            this.curCatalogInfo = this.$refs.tree.getNode(this.curCatalogID).data;
            this.video_times = this.curCatalogInfo.video.video_times
            // this.videoUrl = JSON.stringify({"HD":this.curCatalogInfo.video.video_url,"SD":this.curCatalogInfo.video.video_url});
            this.videoUrl = JSON.stringify({"HD":this.curCatalogInfo.video.video_url,"SD":this.curCatalogInfo.video.video_url});
            let markers = {}
            this.curCatalogInfo.point.forEach(function (item, index) {
                markers = {offset:item.starttime,text:item.name}
                that.markers.push(markers)
            });
            this.setVideo();
            if (!this.isTry) {
              this.getAfterNode(this.catalogData, this.curCatalogInfo);
            }
          });
        }
      });
    },
    async handleNodeClick(data) {
      if (!data.children || data.children.length === 0) {
        this.getAfterNode(this.catalogData, data);
        this.isMute = false
        // 保存用户观看记录
        await this.setUserLiveLog();
        // 未购买
        if (this.courseInfo.course_user1_count === 0 && data.is_try !== 1) {
          // 立即购买
          if (this.courseInfo.is_free === 0) {
            this.downloadDialog = true;
          }
          // 立即报名
          else {
            this.applicationDialog = true;
          }
          return;
        }
        if (data.video) {
          var that = this
          this.bLoading = true;
          this.curCatalogID = data.id;
          this.videoUrl = JSON.stringify({"HD":data.video.video_url,"SD":data.video.video_url});
          this.curCatalogInfo = data;
          let markers = {}
          that.markers = [{}]
          this.curCatalogInfo.point.forEach(function (item, index) {
              markers = {offset:item.starttime,text:item.name}
              that.markers.push(markers)
          });
          this.video_times = this.curCatalogInfo.video.video_times
          this.player.setProgressMarkers(that.markers)
          if (!this.isTry) {
            this.player.dispose()
            this.setVideo();
          }
        }
      }
    },
    getAfterNode(data, node) { // 获取同级后一个节点，data整棵树节点，node当前节点
      let obj = {};
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === node.id) {
          if (i < (data.length - 1)) {
            obj = data[i + 1];
            if (obj.children && obj.children.length > 0) {
              if (obj.children[0].children && obj.children[0].children.length > 0) {
                if (obj.children[0].children[0].children && obj.children[0].children[0].children.length > 0) {
                  this.nextCatalog = obj.children[0].children[0].children[0];
                }
                else {
                  this.nextCatalog = obj.children[0].children[0];
                }
              }
              else {
                this.nextCatalog = obj.children[0];
              }
            }
            else {
              this.nextCatalog = obj;
            }
            return;
          }
          else { // 没有后面一个节点
            if (this.$refs.tree.getNode(data[i].parent_id)) {
              this.getAfterNode(this.catalogData, this.$refs.tree.getNode(data[i].parent_id).data);
            }
          }
        }
        else if (data[i].children) { // 有下级，递归查询
          this.getAfterNode(data[i].children, node);
        }
      }
    },
    /**
     * 保存用户观看记录
     * */
    setUserLiveLog(isOver) {
      if (!this.isTry) {
        const curTime = Math.floor(this.player.getCurrentTime());
        this.reWriteVideoTime(this.catalogData, curTime);
        const params = {
          is_over: isOver || curTime >= this.curCatalogInfo.video.video_duration ? 1 : 0,
          speed: `${(curTime / this.curCatalogInfo.video.video_duration).toFixed(2) * 100}%`,
          chapter_id: this.curCatalogInfo.video.id,
          video_times: curTime
        };
        courseService.addUserLiveLog(params).then(res => {
          if (res.code === 1) {
            // this.reWriteVideoTime(this.catalogData, curTime);
          }
        });
      }
    },
    reWriteVideoTime(data, time) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === this.curCatalogID) {
          data[i].video.video_times = time;
        }
        else if (data[i].children) {
          this.reWriteVideoTime(data[i].children, time);
        }
      }
    },
    /**
     * 关闭静音
     * */
    closeMute() {
      this.player.setVolume(0.5);
      this.isMute = false;
    },
    /**
     * 视频时长转换
     * */
    formatTime(duration) {
      const hour = parseInt(duration / 3600);
      const minute = parseInt((duration % 3600) / 60) > 9 ? parseInt((duration % 3600) / 60) : '0' + parseInt((duration % 3600) / 60);
      const seconds = parseInt(duration % 60) > 9 ? parseInt(duration % 60) : '0' + parseInt(duration % 60);
      const timeStr = `${hour}:${minute}:${seconds}`;
      return timeStr;
    },
    /**
     * 隐藏报名弹窗
     * */
    hideApplicationDialog() {
      this.applicationDialog = false;
    },
    /**
     * 隐藏app弹窗
     * */
    hideDownloadDialog() {
      this.downloadDialog = false;
    },
    /**
     * 首页
     * */
    toHome() {
      this.$router.push({
        path: '/'
      });
    },
    /**
     * 返回课程详情
     * */
    toBack() {
      this.$router.go(-1);
    }
  },
  async beforeDestroy() {
    await this.setUserLiveLog();
    clearInterval(this.saveLogTimeInterval);
    this.saveLogTimeInterval = null;
    if (this.player) {
      this.player.dispose();
    }
  },
  async destroyed() {
    await this.setUserLiveLog();
    clearInterval(this.saveLogTimeInterval);
    this.saveLogTimeInterval = null;
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>

<style lang="scss">
.prism-player .prism-progress{
  height: 8px !important;
  bottom: 60px!important;
}
.prism-player .prism-progress .cursor-hover img,.prism-player .prism-progress .prism-progress-cursor img {

  width: 18px !important;
  height: 18px !important;
}
.prism-player .prism-progress .prism-progress-cursor{
  width: 20px !important;
  height: 20px !important;
}
.prism-player .prism-thumbnail{
  bottom: 70px !important;
}
.prism-player .prism-marker-text{
  bottom: 95px !important;
}
.prism-player .prism-progress .prism-progress-marker .prism-marker-dot{
  top: 0;
  border-radius: 0 !important;
}
.prism-player .prism-time-display{
  color: #fff !important;
}

.prism-player .prism-controlbar{
  height: 64px !important;
}
.prism-player .prism-controlbar .prism-controlbar-bg{
  background: #4b4b4ba6;
  height: 60px !important;
}
.video-page {
  width: 100%;
  //height: 100vh;
  background: #2F2F2F;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .top-nav {
    background: #282828;
    width: 100%;
    .content-wrap {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 14px;
      color: #E0E0E0;
      span {
        cursor: pointer;
      }
    }
  }
  .video-content {
    display: flex;
    height: calc(100vh - 40px );
    position: relative;
    .left-catalog {
      display: flex;
      width: 360px;
      height: 100%;
      background: #282828;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
      .catalog-left {
        .catalog-left-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 80px;
          background: #2F2F2F;
          img {
            width: 16px;
            height: 16px;
            margin-bottom: 8px;
          }
          span {
            font-size: 14px;
            color: #FFFFFF;
          }
        }
      }
      .list-wrap {
        flex: 1;
        background: #2F2F2F;
        padding-top: 20px;
        height: 100%;
        overflow-y: auto;
        .el-tree {
          >.el-tree-node {
            padding: 0 21px 0 12px;
            background: #2F2F2F;
          }
          .el-tree-node {
            white-space: unset;
          }
          .catalog-wrap {
            width: 100%;
          }
          .catalog-wrap-item {
            padding-right: 15px;
            .catalog-name {
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
            }
          }
          .catalog-wrap-last {
            .top {
              display: flex;
              .top-image {
                width: 7px;
                height: 11px;
                margin-right: 8px;
                background: url("../assets/img/video_pause_icon.png") no-repeat 100% 100%;
                margin-top: 4px;
              }
              .catalog-name {
                font-size: 14px;
                font-weight: 400;
                color: #C3C5D9;
                line-height: 20px;
                flex: 1;
              }
              .video-view-process {
                width: 16px;
                height: 16px;
                margin-left: 10px;
                margin-top: 2px;
              }
            }
            .bottom {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: 400;
              color: #77777D;
              padding-left: 15px;
              margin-top: 10px;
              span {
                margin-right: 28px;
              }
            }
          }
          .el-tree-node__children {
            .catalog-name {
              font-size: 14px;
            }
          }
          .el-tree-node__content {
            position: relative;
            width: 100%;
            height: auto;
            line-height: 1;
            padding: 12px 0;
          }
          .el-tree-node__expand-icon {
            position: absolute;
            right: 0;
            top: calc( 50% - 8px );
          }
          .el-tree-node__expand-icon.is-leaf {
            display: none;
          }
          .el-tree-node__expand-icon:before {
            content: '';
          }
          .el-tree-node__expand-icon {
            background: url("../assets/img/video_down_icon.png") no-repeat 100% 100%;
            background-size: 13px 7px;
          }
          .el-tree-node__expand-icon.expanded {
            transform: rotate(0);
            background: url("../assets/img/video_up_icon.png") no-repeat 100% 100%;
          }
          .el-tree-node__content:hover {
            background-color: transparent;
          }
          .el-tree-node:focus > .el-tree-node__content {
            background-color: transparent;
          }
        }
        .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
          background-color: transparent;
          .catalog-wrap-last {
            .top {
              .top-image {
                background: url("../assets/img/video_pause_selected.png") no-repeat 100% 100%;
              }
            }
            .catalog-name {
              color: #2669F4 !important;
            }
          }
        }
      }
      .close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 30px;
        height: 60px;
        top: calc( 50% - 30px );
        right: -30px;
        background: #2F2F2F;
        border-radius: 0px 10px 10px 0px;
        cursor: pointer;
        img {
          width: 11px;
          height: 20px;
        }
      }
    }
    .video-play-wrap {
      position: relative;
      background: #FFFFFF;
      width: 100%;
      height: 100%;
      outline: none;
      .mute-close-icon {
        background: rgba(0,0,0,.4);
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        height: 180px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 196px;
        z-index: 100;
        img {
          display: block;
          height: 56px;
          margin: 46px auto 17px;
          width: 69px;
        }
        span {
          color: #fff;
          display: block;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          margin: 0 auto;
          width: 160px;
        }
      }
      :focus-visible {
        outline: none;
      }
      .player-wrapper {
        height: 100%;
        .video-wrapper {
          height: 100%;
          padding-bottom: 0 !important;
        }
      }
      .loading-wrap {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: 100;
      }
    }
    .open-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 30px;
      height: 60px;
      top: calc( 50% - 30px );
      left: 0;
      background: rgba(47, 47, 47, 0.3);
      border-radius: 0px 10px 10px 0px;
      cursor: pointer;
      img {
        width: 11px;
        height: 20px;
      }
    }
  }
  .video-fluent {
    position: absolute;
    right: 0;
    bottom: 1.8em;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 3em;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    span {
      display: flex;
      justify-content: center;
      width: 100%;
      flex: 1;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
      cursor: pointer;
      line-height: 30px;
      height: 30px;
    }
    span:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .selected {
      background-color: transparent;
      color: #3778FF;
    }
  }

}
</style>
